import React from 'react'
import Image from 'next/image'
import { ContainerScroll } from '@components/ContainerScroll'
import TypeWriter from '@components/TypeWriter'
import { View } from '@cnd/elements'

export function HeroScrollDemo() {
  return (
    <div className="flex flex-col overflow-hidden">
      <ContainerScroll
        titleComponent={
          <>
            <h1 className="text-4xl font-semibold text-black dark:text-white">
              It's time to automate <br />
              <View row center>
                <TypeWriter
                  words={['QSR', 'Staff', 'Shop', 'Kiosk']}
                  renderLetter={(letter) => (
                    <span className="text-4xl md:text-[6rem] font-bold mt-1 leading-none">{letter}</span>
                  )}
                  containerStyle={{ marginRight: 15 }}
                />
                <span className="text-4xl md:text-[6rem] font-bold mt-1 leading-none">Management</span>
              </View>
            </h1>
          </>
        }
      >
        <Image
          src={`/images/hiro_image.webp`}
          alt="hero"
          height={720}
          width={1400}
          className="mx-auto rounded-2xl object-cover h-full object-left-top"
          draggable={false}
        />
      </ContainerScroll>
    </div>
  )
}
